<template>
  <section class="main-single-page page-not-found icon-hea1">
    <button class="open-main-menu" @click="setMenuStatus(true)">
      <icon icon="#cx-hea1-menu" />
    </button>
    <h1>Page not found</h1>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ErrorPage",
  methods: {
    ...mapActions("global", ["setMenuStatus"])
  }
};
</script>
